import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`On a warm and blissful night, a samurai stood deadly still`}<br parentName="p"></br>{`
`}{`Every story has an end, and samurais must know it well`}<br parentName="p"></br>{`
`}{`He looked up at the calm sky, thinking back before his kills`}<br parentName="p"></br>{`
`}{`Samurai took one last glance at his shining sword`}</p>
    <p>{`“This is the way”, he told himself,`}<br parentName="p"></br>{`
`}{`“I took this oath I must fulfill it”`}<br parentName="p"></br>{`
`}{`A sky-blue tear ran down his tired face`}<br parentName="p"></br>{`
`}{`A tradition ran through his blood, one he could not ignore`}</p>
    <p>{`Samurai was part of something larger than himself`}<br parentName="p"></br>{`
`}{`He knew it well and yet he hesitated`}<br parentName="p"></br>{`
`}{`His master saw his worrisome look and slapped him`}<br parentName="p"></br>{`
`}{`It felt like a rock had been thrown through his heart`}</p>
    <p>{`Samurai collapsed onto his knees`}<br parentName="p"></br>{`
`}{`“I can’t do this master, I’m not strong enough” he whispered`}<br parentName="p"></br>{`
`}{`His master picked him up and looked him in the eye`}<br parentName="p"></br>{`
`}{`“This isn’t something you enjoy, but it’s not a choice`}</p>
    <p>{`With that in mind, he closed his eyes and sighed`}<br parentName="p"></br>{`
`}{`He violently penetrated his heart with his own trusty sword`}<br parentName="p"></br>{`
`}{`“I am fulfilling my destiny”, he whimpered as he slowly drifted off`}<br parentName="p"></br>{`
`}{`Into the unknown light`}<br parentName="p"></br>{`
`}{`Guiding him,`}<br parentName="p"></br>{`
`}{`Through darkness`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      